import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from 'components/Layout';
import { Container, Title, Kicker, CardsList, fontStyles } from 'ui';
import { Box, media, Stack } from '@tymate/margaret';
import styled from 'styled-components';
import { Tags, Tag } from 'components/Tags';
import Img from 'gatsby-image';
import ArticleCard from 'components/ArticleCard';
import Seo from 'components/Seo';
import {
  TwitterShareButton,
  LinkedinShareButton,
  FacebookShareButton,
} from 'react-share';
import { FaLinkedinIn, FaTwitter, FaFacebookF } from 'react-icons/fa';
import { renderHtml } from 'utils';

const BackLink = styled(Link)`
  color: #fff;
  text-decoration: none;
`;

const ShareButtons = styled(Stack).attrs(({ variant }) => ({
  gutterSize: variant === 'article' ? 2 : 0.5,
}))`
  color: #fff;

  > button {
    outline: none;
  }

  ${({ variant, theme }) =>
    variant === 'article' &&
    `
      border: 1px solid currentColor;
      color: ${theme.primary};
      padding: ${theme.spacing(0.5)} ${theme.spacing(2)};
      border-radius: 999em;
    `}
`;

const ArticleMeta = styled(Stack).attrs({
  alignX: 'space-between',
  alignY: 'center',
})`
  ${fontStyles.bodySmall}

  position: relative;
  z-index: 1;
  padding-bottom: ${({ theme }) => theme.spacing()};
  padding-top: ${({ theme }) => theme.spacing(3)};
`;

const ArticleFooter = styled(Stack).attrs({
  marginTop: 2,
  size: 'full',
  alignX: 'center',
  direction: 'column',
  gutterSize: 0.5,
})``;

const ArticleFooterTitle = styled(Box)`
  ${fontStyles.bodySmall}

  border-top: 1px solid ${({ theme }) => theme.primary};
  padding-top: ${({ theme }) => theme.spacing(1.5)};
`;

const ArticleHeader = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.primary};
  min-height: 40vh;
`;

const Cover = styled(Img)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  margin-left: ${({ theme }) => theme.spacing(-1)};
  margin-right: ${({ theme }) => theme.spacing(-1)};
  width: calc(100% + ${({ theme }) => theme.spacing(2)});

  ${media.tablet`
    margin-left: ${({ theme }) => theme.spacing(-2)};
    margin-right: ${({ theme }) => theme.spacing(-2)};
    width: calc(100% + ${({ theme }) => theme.spacing(4)});
  `}

  ${media.desktop`
    margin-left: ${({ theme }) => theme.spacing(-4)};
    margin-right: ${({ theme }) => theme.spacing(-4)};
    width: calc(100% + ${({ theme }) => theme.spacing(8)});
  `}
`;

const ArticleBox = styled(Box)`
  background-color: #fff;
  padding: ${({ theme }) => theme.spacing()};
  position: relative;
  z-index: 1;

  ${media.tablet`
    padding: ${({ theme }) => theme.spacing(2)};
  `}

  ${media.tablet`
    padding: ${({ theme }) => theme.spacing(4)};
  `}
`;

const Article = ({ data, location }) => {
  const {
    datoCmsArticle: {
      title,
      tags,
      kickerNode,
      cover,
      contentNode,
      relatedArticles,
      kicker,
    },
  } = data;

  return (
    <Layout>
      <Seo title={title} description={kicker} />
      <ArticleHeader />

      <Container>
        <ArticleMeta>
          <BackLink to="/blog">Retour</BackLink>

          <ShareButtons>
            <span>Partager :</span>
            <TwitterShareButton title={`Urbis – ${title}`} url={location.href}>
              <FaTwitter />
            </TwitterShareButton>
            <LinkedinShareButton title={`Urbis – ${title}`} url={location.href}>
              <FaLinkedinIn />
            </LinkedinShareButton>
            <FacebookShareButton title={`Urbis – ${title}`} url={location.href}>
              <FaFacebookF />
            </FacebookShareButton>
          </ShareButtons>
        </ArticleMeta>

        <ArticleBox>
          <Title>{title}</Title>
          <Tags>
            {tags.map(({ name, slug }, index) => (
              <Tag to={`/blog/categories/${slug}`} key={index}>
                {name}
              </Tag>
            ))}
          </Tags>

          <Kicker>{renderHtml(kickerNode.childMarkdownRemark.htmlAst)}</Kicker>

          {Boolean(cover) && <Cover fluid={cover.fluid} />}

          <Box>{renderHtml(contentNode.childMarkdownRemark.htmlAst)}</Box>

          <ArticleFooter>
            <ArticleFooterTitle>Partager l’article :</ArticleFooterTitle>
            <ShareButtons variant="article">
              <TwitterShareButton
                title={`Urbis – ${title}`}
                url={location.href}
              >
                <FaTwitter />
              </TwitterShareButton>
              <LinkedinShareButton
                title={`Urbis – ${title}`}
                url={location.href}
              >
                <FaLinkedinIn />
              </LinkedinShareButton>
              <FacebookShareButton
                title={`Urbis – ${title}`}
                url={location.href}
              >
                <FaFacebookF />
              </FacebookShareButton>
            </ShareButtons>
          </ArticleFooter>
        </ArticleBox>

        {(relatedArticles || []).length > 0 && (
          <Stack direction="column" gutterSize={1} marginTop={3}>
            <Title as="h2">Continuez votre lecture</Title>

            <CardsList variant="articles">
              {relatedArticles.map(article => (
                <ArticleCard key={article.id} article={article} />
              ))}
            </CardsList>
          </Stack>
        )}
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    datoCmsArticle(slug: { eq: $slug }) {
      id
      slug
      title
      tags {
        slug
        name
      }
      kicker
      kickerNode {
        childMarkdownRemark {
          htmlAst
        }
      }
      cover {
        url
        fluid(maxWidth: 1200) {
          ...GatsbyDatoCmsFluid
        }
      }
      contentNode {
        childMarkdownRemark {
          htmlAst
        }
      }
      relatedArticles {
        id
        slug
        title
        kicker
        meta {
          firstPublishedAt
        }
        cover {
          url
          fluid(maxHeight: 200) {
            ...GatsbyDatoCmsFluid
          }
        }
        tags {
          id
          name
        }
      }
    }
  }
`;

export default Article;
